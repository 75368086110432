import React from 'react';
import './App.css';

const App = () => {
  const files = [
    {
      title: {
        zh: '身體檢查',
        en: 'Health Check Plans'
      },
      id: 'HC19000'
    },
    {
      title: {
        zh: '基本身體檢查',
        en: 'Basic Health Check Plan'
      },
      id: 'HC19001'
    },
    {
      title: {
        zh: '優越身體檢查',
        en: 'Standard Health Check Plan'
      },
      id: 'HC19002'
    },
    {
      title: {
        zh: '白金優越身體檢查',
        en: 'Premium Health Check Plan'
      },
      id: 'HC19003'
    },
    {
      title: {
        zh: '泌尿科男士健康檢查',
        en: 'Urological Men\'s Health Examination'
      },
      id: 'HC19004'
    },
    {
      title: {
        zh: '女士健康檢查',
        en: 'Women\'s Health Examination'
      },
      id: 'HC19005'
    },
    {
      title: {
        zh: '心臟風險檢查',
        en: 'Cardiac Risk Assessment Plan'
      },
      id: 'HC19006'
    },
    {
      title: {
        zh: '中風風險檢查',
        en: 'Stroke Risk Assessment Screening Plan'
      },
      id: 'HC19007'
    },
    {
      title: {
        zh: '大腸及胃內視鏡檢查 (腸胃鏡)',
        en: 'Colonoscopy & Gastroscopy'
      },
      id: 'HC19008'
    },
    {
      title: {
        zh: '睡眠窒息測試',
        en: 'Sleep Apnea Test'
      },
      id: 'HC19009'
    },
    {
      title: {
        zh: '結直腸內視鏡檢查 (腸鏡)',
        en: 'Colonoscopy'
      },
      id: 'HC19010'
    },
    {
      title: {
        zh: '食道胃十二指腸內視鏡檢查 (胃鏡)',
        en: 'Gastroscopy'
      },
      id: 'HC19011'
    },
    {
      title: {
        zh: '鼻咽癌詳細檢查',
        en: 'Advanced Nasopharyngeal Cancer Tests'
      },
      id: 'HC19012'
    },
  ]
  if (window.location.hostname.split('.')[0]==='media') {
    files.push({
      title: {
        zh: '人工受孕服務',
        en: 'In Vitro Fertilization'
      },
      id: 'IVF19001'
    })
  }
  return (
    <div className="filemanager">
      <div className="breadcrumbs">
        <span className="folderName">Health Check Plans</span>
      </div>
      <ul className="data animated">
        {
          files.map(file => (
            <li className="files" key={file.id}>
              <a href={`${file.id}.pdf`} title={`files/${file.id}.pdf`} className="files">
                <span className="icon file f-pdf">.pdf</span>
                <span className="name">{file.title.zh}</span>
                <span className="details">{file.title.en}</span>
              </a>
            </li>
          ))
        }
      </ul>
      <div className="nothingfound" style={{display: 'none'}}>
      <div className="nofiles"></div>
        <span>No files here.</span>
      </div>
    </div>
  );
}

export default App;
